import React from 'react'
import SEO from '../components/seo'
import ComponentParser from '../components/ComponentParser'
import Banner from '../components/banner'

const Page = (props) => {
  return (
    <>
      <SEO data={props.pageContext.seo} />
        <div className="page-content">
          <ComponentParser content={props.pageContext.blocks} />
          <Banner {...props.pageContext.options.footerBanner}></Banner>
        </div>
    </>
  )
}

export default Page